import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Confidential Information Request</h2>
        <p>Privacy and respect is a core tenet at HNW Realty</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>We will respond promptly</h3>
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <p>
              <input type="hidden" name="form-name" value="contact" />
            </p>
            <p>
              <label>Name <input type="text" name="name" /></label>   
            </p>
            <p>
              <label>Email <input type="email" name="email" /></label>
            </p>
            <p>
              <label>Phone <input type="text" name="email" /></label>
            </p>
            <p>
              <label>Reason for contacting us <textarea name="message" rows="7"></textarea></label>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
